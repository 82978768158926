<template>
  <div class="max-w mx-auto self-start mt-2">
    <h2 class="text-lg font-medium text-gray-900">Choose option</h2>
    <p class="mt-1 text-sm text-gray-500">Get started by selecting what you want to do.</p>
    <ul role="list" class="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
      <li v-for="(item, itemIdx) in items" :key="itemIdx">
        <div :class="['relative group py-4 flex items-start space-x-3 hover:bg-gray-50 select-none', (item.permission ? 'opacity-1': 'opacity-40 cursor-pointer pointer-events-none')]">
          <div class="flex-shrink-0">
            <span :class="[item.iconColor, 'inline-flex items-center justify-center h-10 w-10 rounded-lg']">
              <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div class="min-w-0 flex-1">
            <div class="text-sm font-medium text-gray-900">
              <router-link :to="item.permission ? item.href : ''" v-if="item.href.length>0">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ item.name }}
              </router-link>
              <div class="cursor-pointer" v-if="item.href.length===0 && item.specialOption === 'csv'" @click.prevent="importCSV">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ item.name }}
              </div>
            </div>
            <p class="text-sm text-gray-500">{{ item.description }}</p>
          </div>
          <div class="flex-shrink-0 self-center">
            <ChevronRightIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          </div>
        </div>
      </li>
    </ul>
    <input type="file" @change="readCSV"  ref="hiddenCSV" v-show="false" accept=".csv">
    <div class="mt-4">
      <button @click.prevent="downloadSampleCSV" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sample product .csv file</button>
      <button @click.prevent="aboutCSV" type="button" class="ml-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">About .csv</button>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { ViewListIcon, PlusIcon, TableIcon, CubeTransparentIcon, DatabaseIcon, CloudUploadIcon, ColorSwatchIcon } from '@heroicons/vue/outline';
import Papa from 'papaparse';
import {db} from "@/firebase/gfbconf.js";
import moment from "moment"
import axios from "axios"
//import { writeBatch} from "firebase/firestore"; 


// const items = [
//   {
//     name: 'Products',
//     description: 'View, modify or delete existing product.',
//     href: '/dashboard/product/list',
//     iconColor: 'bg-indigo-400',
//     icon: ViewListIcon,
//     specialOption: null,
//   },
//   {
//     name: 'Create new product',
//     description: 'Add new product to database.',
//     href: '/dashboard/product/add',
//     iconColor: 'bg-purple-400',
//     icon: PlusIcon,
//     specialOption: null,
//   },
//   {
//     name: 'Import CSV',
//     description: 'Import multiple products at once.',
//     href: '',
//     iconColor: 'bg-yellow-600',
//     icon: CloudUploadIcon,
//     specialOption: 'csv',
//   },
//   {
//     name: 'Attributes',
//     description: 'Prepare attributes for products.',
//     href: '/dashboard/product/attributes',
//     iconColor: 'bg-red-300',
//     icon: ColorSwatchIcon,
//     specialOption: null,
//   },
//   {
//     name: 'Categories',
//     description: 'Create, modify and delete product categories.',
//     href: '/dashboard/product/categories',
//     iconColor: 'bg-blue-400',
//     icon: TableIcon,
//     specialOption: null,
//   },
//   {
//     name: 'Manufacturers',
//     description: 'Manage product manufacturers.',
//     href: '/dashboard/product/manufacturers',
//     iconColor: 'bg-green-300',
//     icon: CubeTransparentIcon,
//     specialOption: null,
//   },
//   {
//     name: 'Warehouses',
//     description: 'Add new or modify item storage.',
//     href: '/dashboard/product/warehouses',
//     iconColor: 'bg-purple-600',
//     icon: DatabaseIcon,
//     specialOption: null,
//   }
// ]

export default {
  components: {
    ChevronRightIcon,
  },
  data() {
    return {
      items: [
        {
          name: 'Products',
          description: 'View, modify or delete existing product.',
          href: '/dashboard/product/list',
          iconColor: 'bg-indigo-400',
          icon: ViewListIcon,
          specialOption: null,
          permission: true,
        },
        {
          name: 'Create new product',
          description: 'Add new product to database.',
          href: '/dashboard/product/add',
          iconColor: 'bg-purple-400',
          icon: PlusIcon,
          specialOption: null,
          permission: this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingProducts && !this.$store.state.userData.systemCreator
        },
        {
          name: 'Import CSV',
          description: 'Import multiple products at once.',
          href: '',
          iconColor: 'bg-yellow-600',
          icon: CloudUploadIcon,
          specialOption: 'csv',
          permission: this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingProducts && !this.$store.state.userData.systemCreator
        },
        {
          name: 'Attributes',
          description: 'Prepare attributes for products.',
          href: '/dashboard/product/attributes',
          iconColor: 'bg-red-300',
          icon: ColorSwatchIcon,
          specialOption: null,
          permission: this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingProducts && !this.$store.state.userData.systemCreator
        },
        {
          name: 'Categories',
          description: 'Create, modify and delete product categories.',
          href: '/dashboard/product/categories',
          iconColor: 'bg-blue-400',
          icon: TableIcon,
          specialOption: null,
          permission: this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingProducts && !this.$store.state.userData.systemCreator
        },
        {
          name: 'Manufacturers',
          description: 'Manage product manufacturers.',
          href: '/dashboard/product/manufacturers',
          iconColor: 'bg-green-300',
          icon: CubeTransparentIcon,
          specialOption: null,
          permission: this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingProducts && !this.$store.state.userData.systemCreator
        },
        {
          name: 'Warehouses',
          description: 'Add new or modify item storage.',
          href: '/dashboard/product/warehouses',
          iconColor: 'bg-purple-600',
          icon: DatabaseIcon,
          specialOption: null,
          permission: this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingProducts && !this.$store.state.userData.systemCreator
        }
      ]
    }

  },
  methods:
  {
    async addProductsToAnalytics(counter) {
      const refToYear = this.$store.state.userData.system.ref.collection('Analytics').doc(moment().year().toString())
      let currentMonth = moment(moment().month() + 1, 'MM').format('MMMM')

      let globalTemplate = {
                    complaints: {
                    active: 0,
                    addedByClient: 0,
                    addedManually: 0,
                    casesAmount: 0,
                    chargeback: {
                        quantity: 0,
                        worth: 0
                    },
                    complainedProductsQuantity: 0,
                    complaintsWorth: 0,
                    created: 0,
                    declined: 0,
                    deliveryData: {
                        delivery: 0,
                        deliveryAndPickup: 0,
                        pickup: 0
                    },
                    finished: 0,
                    priorityStats: {
                        Level1: 0,
                        Level2: 0,
                        Level3: 0,
                        Level4: 0,
                        Level5: 0,
                    }
                    },
                    productCreator: {
                    added: 0,
                    deleted: 0
                    },
                    products: []
                }
                // prepare analitycs objects
                let yearData = (await refToYear.get()).data()
                if(yearData === undefined) {
                    yearData = {}
                    yearData.year = moment().year()
                    yearData.global = JSON.parse(JSON.stringify(globalTemplate))
                }

                let monthData = yearData[moment(moment().month() + 1, 'MM').format('MMMM')]
                if(monthData === undefined) {
                    yearData[currentMonth] = {}
                    yearData[currentMonth].global = JSON.parse(JSON.stringify(globalTemplate))
                    yearData[currentMonth].days = {} 
                    monthData = yearData[moment(moment().month() + 1, 'MM').format('MMMM')]
                }

                let dayData = monthData.days[moment().date()]
                if(dayData === undefined) {
                    monthData.days[moment().date()] = {}
                    monthData.days[moment().date()].global = JSON.parse(JSON.stringify(globalTemplate))
                    dayData = monthData.days[moment().date()]
                }

                yearData.global.productCreator.added += counter
                monthData.global.productCreator.added += counter
                dayData.global.productCreator.added += counter
                await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(moment().year().toString()).set(yearData);
    },
    importCSV()
    {
      this.$refs.hiddenCSV.click();
    },
    downloadSampleCSV()
    {
      //https://en.wikipedia.org/wiki/Comma-separated_values
      const url = 'https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/samples%2FProductSample.csv?alt=media&token=112c249b-8f5d-4a4f-9402-ea1edb3154eb'
      window.open(url, '_blank').focus();
    },
    aboutCSV()
    {
      const url = 'https://en.wikipedia.org/wiki/Comma-separated_values';
      window.open(url, '_blank').focus();
    },
    // Przypisanie wartości do produktu
    addValue(arr,value) {
      let name = ""
      let ref = null
      let id = ""
      arr.forEach(item => {
        if (item.id.toString() === value){
          name = item.data().name
          ref = item.ref
          id = item.data().id
        } 
      })
      return {
        name: name,
        ref: ref,
        id: id,
      }
    },
    async readCSV(e) {
        this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Importing products from CSV. Now we have imported 0 products",
            subheader: `Do not close this tab.`,
        });
        let isError = false
        let addedProducts = []
        let addedProductsElastic = []
        let counter = 0
        let warehouses = []
        let categories = []
        let manufacturers = []
        // Pobranie danych z db
        const categoriesRef = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Categories").get()
        const manufacturersRef = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Manufacturers").get()
        const warehousesRef = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Warehouses").get()
        categoriesRef.forEach(category => {
          categories.push(category)
        })
        manufacturersRef.forEach(manufacturer => {
          manufacturers.push(manufacturer)
        })
        warehousesRef.forEach(warehouse => {
          warehouses.push(warehouse)
        })
        // Odczytanie pliku csv
         Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            step: async (results, parser) => {
                  parser.pause()
                  this.$store.state.globalLoader.head = `Importing products from CSV. Now we have imported ${counter} ${counter === 1 ? 'product' : 'products'}`
                  let categoryId = results.data.CategoryID ? results.data.CategoryID : "failed"
                  let manufacturerId = results.data.ManufacturerID ? results.data.ManufacturerID : "failed";
                  let warehouseId = results.data.WarehouseID ? results.data.WarehouseID : "failed"
                  let productId = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Products").doc().id
                  if(productId.length > 0) {
                    let product = {
                      name: results.data.Name === undefined || results.data.Name.length === 0 ? "No item name" : results.data.Name,
                      model: results.data.Model === undefined || results.data.Model.length === 0 ? "" : results.data.Model,
                      active: true,
                      meta: { 
                          createdBy: null,
                          createdDate: null,
                          lastModificationDate: null,
                          listOfChanges: []
                      },
                      ean: results.data.EAN === undefined || results.data.EAN.length === 0 ? "" : results.data.EAN,
                      sku: results.data.SKU === undefined || results.data.SKU.length === 0 ? "" : results.data.SKU,
                    image: {
                      ref: '',
                      url: results.data.ImageURL === undefined || results.data.ImageURL.length === 0 ? "" : results.data.ImageURL
                    },
                    manufacturer: this.addValue(manufacturers, manufacturerId),
                    category: this.addValue(categories, categoryId),
                    elasticSearch: {
                      docId: "",
                      index: "",
                      creationDate: ""
                    },
                    extraField1: results.data.ExtraField1 === undefined || results.data.ExtraField1.length === 0 ? "" : results.data.ExtraField1,
                    extraField2: results.data.ExtraField2 === undefined || results.data.ExtraField2.length === 0 ? "" : results.data.ExtraField2,
                    extraField3: results.data.ExtraField3 === undefined || results.data.ExtraField3.length === 0 ? "" : results.data.ExtraField3,
                    id: productId,
                    description: results.data.Description === undefined || results.data.Description.length === 0 ? "" : results.data.Description,
                    pricesAndCosts: {
                      currency: "USD",
                      sellPrice: results.data.SellPriceUSD === undefined || results.data.SellPriceUSD.length === 0 ? 0 : parseFloat(parseFloat(results.data.SellPriceUSD).toFixed(2)),
                      complaintCost: results.data.ComplaintCostUSD === undefined || results.data.ComplaintCostUSD.length === 0 ? 0 : parseFloat(parseFloat(results.data.ComplaintCostUSD).toFixed(2)),
                      productionCost: results.data.ProductionCostUSD=== undefined || results.data.ProductionCostUSD.length === 0 ? 0 : parseFloat(parseFloat(results.data.ProductionCostUSD).toFixed(2))
                    },
                    storage: {
                      warehouse: this.addValue(warehouses, warehouseId),
                      location: results.data.Location === undefined || results.data.Location.length === 0 ? "" : results.data.Location,
                      additionalInfo: results.data.WarehouseAdditionalInfo === undefined || results.data.WarehouseAdditionalInfo.length === 0 ? "" : results.data.WarehouseAdditionalInfo
                    },
                      link: {
                      href: results.data.ExternalURL === undefined || results.data.ExternalURL.length === 0 ? "" : results.data.ExternalURL
                    },
                    attribs: {
                          list: []
                      }
                    
                  }
                  // Added product to elastic search
                   const elasticResponse = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/add/product", {
                          doc: {
                              name: product.name,
                              manufacturer: product.manufacturer.name,
                              model: product.model,
                              ean: product.ean,
                              sku: product.sku,
                              warehouse: product.storage.warehouse.name,
                              location: product.storage.location,
                              category: product.category.name
                            },
                          system: this.$store.state.userData.system.id
                      }).catch((error)=>
                      {
                          console.log(error);
                      });
                      if(elasticResponse.data.status === "ok")
                      {
                          product.elasticSearch = elasticResponse.data.elasticSearch;
                      }
                      product.meta.createdDate = moment().toDate();
                      product.meta.lastModificationDate = moment().toDate();
                      product.meta.createdBy = this.$store.state.user.displayName;
                      product.meta.userId = this.$store.state.user.uid;

                  // Added attributes
                  const attributesRef = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Attributes")
                  results.data.AttributesID === undefined || results.data.AttributesID.length === 0 ? console.log() : results.data.AttributesID.split(',').forEach(async categoryID => {
                    const attributesResult = await attributesRef.doc(parseInt(categoryID) ? categoryID : "failed").get()
                    if(attributesResult.exists) {
                      let attribute = {
                      id: attributesResult.data().id,
                      meta: {
                        addedBy: this.$store.state.user.displayName,
                        userId: this.$store.state.user.uid,
                        creationDate: moment().toDate(),
                        name: attributesResult.data().name
                      },
                      parent: {
                        id: attributesResult.data().id,
                        name: attributesResult.data().name,
                        ref: attributesResult.data().groupOfAttributes.ref,
                        type: attributesResult.data().type,
                      },
                      ref: attributesResult.ref,
                    } 
                      product.attribs.list.push(attribute); 
                    }
                  });
                  try {
                    if(!isError) {
                      await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Products").doc(productId).set(product);
                      addedProducts.push(productId)
                      addedProductsElastic.push(product.elasticSearch.docId)
                      counter += 1
                    }
                    parser.resume()           
                  } catch(error) {
                    isError = true
                    parser.abort()
                  }
                  } else {
                    isError = true
                    parser.abort()
                  }                
          },
          complete: async () => {
              if(!isError) {
                this.$store.state.userData.system.ref.collection("Events").add(
                    {
                    user: {
                        name: this.$store.state.user.displayName,
                        photo: this.$store.state.user.photoURL
                    },
                    creationDate: moment().toDate(),
                    eventName: `${counter} ${counter === 1 ? 'product was imported from a CSV file.' : 'products were imported from a CSV file.'}`
                }).catch((error)=>
                {
                console.log(error);
                });
                await this.addProductsToAnalytics(counter)
                this.$store.commit("resetGlobalLoader");
                this.$store.commit('setNotification',{
                    show: true,
                    head: `Imported successfully.`,
                    subheader: `Imported ${counter} products from CSV file.`,
                    success: true
                });
              } else {
                  
                  for(let y = 0; y < addedProducts.length; y++) {
                    db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Products").doc(addedProducts[y]).delete()
                  }
                  // Delete from Elastic Search index
                  await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/delete",{
                    system: this.$store.state.userData.system.id,
                    docsToDelete: addedProductsElastic,
                    type: 'products'
                  });

                  this.$store.commit("resetGlobalLoader");
                  this.$store.commit('setNotification',{
                    show: true,
                    head: `Something went wrong or there was no products to import.`,
                    subheader: `We aborted all imports, please try again`,
                    success: false
                });   
              }
              this.$refs.hiddenCSV.value = ""
            }
        })
        }

  },
}
</script>